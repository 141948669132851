<template>
  <v-container id="user" fluid tag="section">
    <v-card>
      <v-alert color="red lighten-2" dark v-if="showEmailError">
        {{ showErrorEmail }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showPhoneNumberError">
        {{ showErrorPhoneNumber }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showVehicleNumberError">
        {{ showErrorVehicleNumber }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showDriverLicenceNumberError">
        {{ showErrorDriverLicenceNumber }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showDriverPhotoError">
        {{ showErrorDriverPhoto }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showInsurancePhotoError">
        {{ showErrorInsurancePhoto }}
      </v-alert>
      <v-alert color="red lighten-2" dark v-if="showLicencePhotoError">
        {{ showErrorLicencePhoto }}
      </v-alert>
      <v-form ref="form">
        <v-container class="py-2">
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                label="First Name"
                class="purple-input"
                v-model="first_name"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Last Name"
                class="purple-input"
                v-model="last_name"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                label="Email"
                class="purple-input"
                v-model="email"
                :rules="emailRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Phone Number"
                class="purple-input"
                v-model="phone_number"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6">
              <v-select
                :items="userType"
                label="Select User Type"
                item-text="name"
                item-value="_id"
                v-model="userTypeId"
                :rules="selectRule"
                :disabled="true"
              ></v-select>
            </v-col>
            <v-col
              cols="6"
              md="6"
            >
              <v-select
                :items="stores"
                label="Select Store"
                item-text="name"
                item-value="_id"
                v-model="storeId"
                :rules="selectRule"
                :disabled="onlyView"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              md="6"
            >
              <v-select
                :items="roles"
                label="Select Role"
                item-text="name"
                item-value="_id"
                v-model="roleId"
                :rules="selectRule"
                :disabled="onlyView"
              ></v-select>
            </v-col>
            <v-col cols="6" md="6" v-if="!editUser">
              <v-text-field
                v-model="password"
                label="Password"
                hint="At least 6 characters"
                :type="show1 ? 'text' : 'password'"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                counter
                @click:append="show1 = !show1"
                :rules="passwordRule"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                type="button"
                color="primary"
                class="mr-0"
                @click="submit"
                :loading="loading"
              >
                {{
                  onlyView == true
                    ? "Back"
                    : editUser == true
                    ? "Update"
                    : "Submit"
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ["id", "action"],
  data() {
    return {
      editUser: false,
      showEmailError: false,
      showErrorEmail: "",
      showPhoneNumberError: false,
      showErrorPhoneNumber: "",
      showVehicleNumberError: false,
      showErrorVehicleNumber: "",
      showErrorDriverLicenceNumber: "",
      showDriverLicenceNumberError: false,
      showErrorDriverPhoto: "",
      showDriverPhotoError: false,
      showErrorInsurancePhoto: "",
      showInsurancePhotoError: false,
      showErrorLicencePhoto: "",
      showLicencePhotoError: false,
      stores: [],
      userType: [],
      roles: [],
      storeId: "",
      userTypeId: "",
      roleId: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      age: "",
      show1: false,
      imageDelete: false,
      deleteImageConfirm: "",
      deleteFieldConfirm: "",
      imageUrl: process.env.VUE_APP_Image_Link,
      inputRule: [(v) => !!v || "Field is required"],
      selectRule: [(v) => !!v || "Select any option"],
      passwordRule: [(v) => v.length >= 6 || "Minimum length is 6 characters"],
      imageRule: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
      emailRule: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      onlyView: false,
      loading: false,
    };
  },
  methods: {
    getRolesList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "role/getall")
        .then((response) => {
          if (response.status == 200) {
            this.roles = response.data.roles;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    userTypeList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "userType/getall")
        .then((response) => {
          if (response.status == 200) {
            this.userType = response.data.user_type;
            if (this.onlyView != true && this.editUser != true) {
              // for default user type while adding new user
              this.userType.forEach((element) => {
                if (element.name == "Super Admin") {
                  this.userTypeId = element._id;
                }
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStoreList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "store/getall")
        .then((response) => {
          if (response.status == 200) {
            this.stores = response.data.stores;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getUserData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "user/edit/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.first_name = response.data.user.first_name;
            this.last_name = response.data.user.last_name;
            this.email = response.data.user.email;
            this.phone_number = response.data.user.phone_number;
            this.userTypeId = response.data.user.user_type[0]._id;
            this.storeId = response.data.user.store_name[0]._id;
            this.roleId = response.data.user.role_name[0]._id;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    openNewTab(url) {
      window.open(url, "_blank");
    },
    submit() {
      this.showEmailError = false;
      this.showPhoneNumberError = false;

      this.loading = true;
      let formData = new FormData();
      if (this.editUser == true) {
        formData.append("id", this.id);
      }
      formData.append("first_name", this.first_name);
      formData.append("last_name", this.last_name);
      formData.append("email", this.email);
      formData.append("phone_number", this.phone_number);
      formData.append("user_type_id", this.userTypeId);
      formData.append("store_id", this.storeId);
      formData.append("role_id", this.roleId);
      formData.append("password", this.password);
      formData.append("age", this.age);
      // // Display the key/value pairs
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      if (this.onlyView) {
        this.$router.push({ name: "Admin Staff" });
      } else if (this.editUser == true) {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "user/update", formData)
          .then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.$router.push({
                name: "Admin Staff",
                params: { edit: true },
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.data.email != null) {
              this.showErrorEmail = error.response.data.email[0];
              this.showEmailError = true;
            }
            if (error.response.data.phone_number != null) {
              this.showErrorPhoneNumber = error.response.data.phone_number[0];
              this.showPhoneNumberError = true;
            }
          });
      } else {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "user/create", formData)
          .then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.$router.push({
                name: "Admin Staff",
                params: { add: true },
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.data.email != null) {
              this.showErrorEmail = error.response.data.email[0];
              this.showEmailError = true;
            }
            if (error.response.data.phone_number != null) {
              this.showErrorPhoneNumber = error.response.data.phone_number[0];
              this.showPhoneNumberError = true;
            }
          });
      }
    },
  },
  mounted() {
    const route = this.$router.currentRoute;
    this.editUser = false;
    this.userTypeList();
    this.getRolesList();
    this.getStoreList();
    if (this.id != undefined) {
      this.getUserData(this.id);
    }
    if (route.name == "View Admin Staff") {
      this.onlyView = true;
    }
    if (route.name == "Edit Admin Staff") {
      this.editUser = true;
    }
  },
};
</script>

<style>
</style>